import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  static targets = ["client", "tips"]
  connect(){
    new TomSelect(this.clientTarget,{
	    create: true,
	    sortField: {
	    	field: "text",
	    	direction: "asc"
	    }
    })
  }

  tipsfield(event){
    if (event.target.value == "card") {
      this.tipsTarget.classList.remove("tips-field")
    } else {
      this.tipsTarget.classList.add("tips-field")
    }
  }
}
